import { template as template_6071fe5de10c4ca88a3aefe5780bd8df } from "@ember/template-compiler";
const FKLabel = template_6071fe5de10c4ca88a3aefe5780bd8df(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
