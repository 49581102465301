import { template as template_8965bdd44b644fc99c14e213297b1929 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8965bdd44b644fc99c14e213297b1929(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
