import { template as template_dfea494bd30d47709d43f435fea61411 } from "@ember/template-compiler";
const WelcomeHeader = template_dfea494bd30d47709d43f435fea61411(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
